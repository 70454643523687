import React from "react";

const ContactButtons = () => {
  return (
    <div>
      <div className="contact-button">

      <div className="call-button">
      <a title="contact" className="call-button-icon"  target="_blank" href="tel:+919871599433">
              <i class="fa-solid fa-phone-volume"></i>
          </a>
    </div>

    <div className="whatsapp-button">
      <a title="contact" className="whatsapp-button-icon" target="_blank" href="https://wa.me/+919871599433?text=Hello ! I'm Interested in Desktop/Laptop Service. Please contact me.">
        <i class="fa-brands fa-whatsapp"></i>
      </a>
    </div>

      </div>
    </div>
  );
};

export default ContactButtons;
