import React from 'react'
import './App.css';
import "./css/HeaderComponent.css"
import "./css/ContactButtons.css"
import "./css/Animation.css"
import "./css/Loading.css"
import "./css/ErrorPage.css"

import LandingPage from './pages/LandingPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ConfirmBookingPage from './pages/ConfirmBookingPage';
import ErrorPage from './pages/ErrorPage';

const App = () => {
  return (
    <div>

<BrowserRouter>
        <Routes>
          <Route path='/' element={<LandingPage />}></Route>
          <Route path='/booking-confirm' element={<ConfirmBookingPage />}></Route>
          <Route path='/*' element={<ErrorPage />}></Route>
          
        </Routes>
      </BrowserRouter>

      
    </div>
  )
}

export default App