import React from 'react'
import { Link } from 'react-router-dom'

const ConfirmBookingPage = () => {
  return (
    <div>
       
        <div>
      <div className="error-page">
        <h1>Inquiry Raised Successfully</h1>
        <h2>Our team will contact you shortly</h2>
        <h3>
          Contact : <Link target='_blank' to="tel:+919871599433">+91 98715 99433</Link>
        </h3>
        <h3>
          <Link to="/">go to home <i className="fa-solid fa-right-to-bracket"></i></Link>
        </h3>
      </div>
    </div>


    
    </div>
  )
}

export default ConfirmBookingPage