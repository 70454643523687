import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";



import laptop from "../media/images/icons/laptop.webp";
import mac from "../media/images/icons/macbook.webp";
import computer from "../media/images/icons/computer.webp";
import amc from "../media/images/icons/laptop-AMC.webp";
import windows from "../media/images/icons/windows.webp";
import software from "../media/images/icons/software1.webp";

import pickup from "../media/images/icons/free-doorstep-service-icon.svg";
import quality from "../media/images/icons/high-quality-spares-icon.svg";
import diagnosis from "../media/images/icons/Group 5.svg";
import expert from "../media/images/icons/Group 7.svg";


import visit from "../media/images/icons/visit.svg";
import ontime from "../media/images/icons/ontime.svg";
import bestcustomer from "../media/images/icons/bestcustomer.svg";
import LoadingComponent from "./LoadingComponent";









const HeaderComponent = () => {

  const [loading, setLoading] = useState(false)

     
  const [user, setUser] = useState({
    name: "",
    number: "",
    address:"",
    message :"_blank",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setUser({ 
      ...user,
      [e.target.name]: value,
    });
  };



  
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      const response = await fetch("https://api.repair.bhumu.com/inquiry-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        alert("Inquiry raised successfully");
        navigate("/booking-confirm");

      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }

    } catch (error) {
      alert("server error");
    }
    setLoading(false);

  };
    
  
  
  
  return (
    <header>

{loading 
?
<div className="data-loading">
<LoadingComponent/>
</div>

:("")
}
      
      <div className="header">

        <div className="header-options">
          <h1 className="header-options-heading">We are <span>best</span> in</h1>
          <div className="header-options-content">

            <Link title="contact"  target="_blank" to="https://wa.me/+919871599433?text=Hello ! I'm Interested in Laptop Repair Service. Please contact me."  className="header-options-content-col">
              <div className="header-options-content-col-icon">
                <img src={laptop} alt="icon" title="icon" />
              </div>
              <div className="header-options-content-col-text">
                Laptop Repair
              </div>
            </Link>

            <Link title="contact"  target="_blank" to="https://wa.me/+919871599433?text=Hello ! I'm Interested in MacBook Repair Service. Please contact me."  className="header-options-content-col">
              <div className="header-options-content-col-icon">
                <img src={mac} alt="icon" title="icon" />
              </div>
              <div className="header-options-content-col-text">
                MacBook Repair
              </div>
            </Link>

            <Link title="contact"  target="_blank" to="https://wa.me/+919871599433?text=Hello ! I'm Interested in Computer Repair Service. Please contact me."  className="header-options-content-col">
              <div className="header-options-content-col-icon">
                <img src={computer} alt="icon" title="icon" />
              </div>
              <div className="header-options-content-col-text">
                Computer Repair
              </div>
            </Link>

            <Link title="contact"  target="_blank" to="https://wa.me/+919871599433?text=Hello ! I'm Interested in Laptop AMC Service. Please contact me."  className="header-options-content-col">
              <div className="header-options-content-col-icon">
                <img src={amc} alt="icon" title="icon" />
              </div>
              <div className="header-options-content-col-text">Laptop AMC</div>
            </Link>

            <Link title="contact"  target="_blank" to="https://wa.me/+919871599433?text=Hello ! I'm Interested in Windows Installation Service. Please contact me."  className="header-options-content-col">
              <div className="header-options-content-col-icon">
                <img src={windows} alt="icon" title="icon" />
              </div>
              <div className="header-options-content-col-text">
                Windows Installation
              </div>
            </Link>

            <Link title="contact"  target="_blank" to="https://wa.me/+919871599433?text=Hello ! I'm Interested in Software Installation Service. Please contact me."  className="header-options-content-col">
              <div className="header-options-content-col-icon">
                <img src={software} alt="icon" title="icon" />
              </div>
              <div className="header-options-content-col-text">
                Software Installation
              </div>
            </Link>
          </div>
        </div>
        
        <div className="header-form">

          <div className="header-form-icon">
            <di className="header-form-icon-col">
              <div className="header-form-icon-col-icon">
                <img src={pickup} alt="icon" title="icon" />
              </div>
              <div className="header-form-icon-col-text">Free Pickup <br />& Drop</div>
            </di>

            <div className="header-form-icon-col">
              <div className="header-form-icon-col-icon">
                <img src={quality} alt="icon" title="icon" />
              </div>
              <div className="header-form-icon-col-text">High Quality <br />Spares</div>
            </div>

            <div className="header-form-icon-col">
              <div className="header-form-icon-col-icon">
                <img src={diagnosis} alt="icon" title="icon" />
              </div>
              <div className="header-form-icon-col-text">Free <br />Diagnosis</div>
            </div>

            <div className="header-form-icon-col">
              <div className="header-form-icon-col-icon">
                <img src={expert} alt="icon" title="icon" />
              </div>
              <div className="header-form-icon-col-text">Expert <br />Technicians</div>
            </div>
          </div>

          {/* --------------------------------------------------- */}

          <form onSubmit={handleSubmit}>
            <input required onChange={handleChange} name="name" type="text" placeholder="Name*" />
            <input required onChange={handleChange} name="number" type="text" placeholder="Mobile No*" />
            <input required onChange={handleChange} name="address" type="text" placeholder="Address/City*" />
            <input onChange={handleChange} name="message" type="text" placeholder="Any message" />
            <button>REQUEST CALL BACK</button>
          </form>


          {/* --------------------------------------------------- */}

          <div className="header-rating">
            <div className="header-rating-col">
              <div className="header-rating-col-heading">4.8/5</div>
              <div className="header-rating-col-text">Based on 1200+ <br />Reviews</div>
            </div>

            <div className="header-rating-col">
              <div className="header-rating-col-heading">30,000+</div>
              <div className="header-rating-col-text">Happy <br />Customers</div>
            </div>
          </div>


          {/* --------------------------------------------------- */}


          <div className="header-form-end">
            
            <div className="header-form-end-col">
              <div className="header-form-end-col-icon">
                <img src={visit} alt="icon" title="icon" />
              </div>
              <div className="header-form-end-col-text">Visit Within <br />One Hour</div>
            </div>

            <div className="header-form-end-col">
              <div className="header-form-end-col-icon">
                <img src={ontime} alt="icon" title="icon" />
              </div>
              <div className="header-form-end-col-text">On Time <br />Delivery</div>
            </div>

            <div className="header-form-end-col">
              <div className="header-form-end-col-icon">
                <img src={bestcustomer} alt="icon" title="icon" />
              </div>
              <div className="header-form-end-col-text">Best Customer <br />Support</div>
            </div>
            
          </div>

        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;
